import React, { 
    useEffect, 
    useState 
} from 'react';
import {
    Sidebar,
    Menu,
    MenuItem,
    SubMenu,
} from './components/';
import { Link } from 'react-router-dom';
import SidebarContainer from './components/SidebarContainer';
import { Trans } from '@lingui/macro';
import { measureText } from 'utils';
import { useLingui } from '@lingui/react';
import { useDataContext, Icons } from '@nplan';
const SidebarMenu: React.FC = (): JSX.Element => {
    const { i18n } = useLingui();
    const [width, setWidth] = useState(260);
    const [collapsed, setCollapsed] = useState(true);
    const { 
        tables, 
        groups, 
        reports,
        hasMenuAccess
    } = useDataContext();
    const handleOpenMenu = () => {
        setCollapsed(false);
    };
    const handleCloseMenu = () => {
        setCollapsed(true);
    };
    useEffect(() => {
        (async () => {
            let widthInitial = 250;
            tables.forEach((e) => {
                const width =
                    measureText(
                        i18n.t(e.title),
                        '19px Roboto',
                    ) + 80;
                if (widthInitial < width) {
                    widthInitial = width;
                }
            });
            setWidth(widthInitial);
        })();
    }, [i18n, tables]);
    return (
        <SidebarContainer>
            <Sidebar
                collapsed={collapsed}
                onMouseEnter={handleOpenMenu}
                onMouseLeave={handleCloseMenu}
                width={`${width}px`}
            >
                <Menu
                >
                    {hasMenuAccess('HOME') && 
                        <MenuItem
                            component={<Link to='/home' />}
                            icon={<Icons.Home size='1.6em' />}
                            
                        >
                            <Trans>Home</Trans>
                        </MenuItem>
                    }
                    
                    {hasMenuAccess('ANALYSIS') && 
                        <SubMenu
                            label={i18n.t('Análise')}
                            icon={
                                <Icons.Analise size='1.6em' />
                            }
                        >
                            <MenuItem
                                component={
                                    <Link
                                        to={`new-order`}
                                    />
                                }
                                icon={
                                    <Icons.PoliticaEstoque size='1.6em' />
                                }
                            >
                                <Trans>
                                    Simulador de Pedidos
                                </Trans>
                            </MenuItem>
                            <MenuItem
                                component={
                                    <Link
                                        to={`orders`}
                                    />
                                }
                                icon={
                                    <Icons.PoliticaEstoque size='1.6em' />
                                }
                            >
                                <Trans>
                                    Listagem
                                </Trans>
                            </MenuItem>
                        </SubMenu>
                    }
                    {hasMenuAccess('GANTT') &&
                        <MenuItem
                            component={<Link to='/gantt' />}
                            icon={
                                <Icons.Planejamento size='1.6em' />
                            }
                        >
                            <Trans>Sequenciamento</Trans>
                        </MenuItem>
                    }
                    {hasMenuAccess('DATAEDITION') &&
                        <SubMenu
                            label={i18n.t(`Dados`)}
                            icon={<Icons.Table size='1.5em' />}
                        >
                            {groups
                                ?.sort((a, b) =>
                                    i18n.t(a.name)
                                        .localeCompare(i18n.t(b.name)),
                                )
                                .filter(f => tables.some(t => t.groupId === f.id))
                                .map((g) => (
                                    <SubMenu
                                        label={i18n.t(g.name)}
                                        key={g.id}
                                    >
                                        {tables
                                            ?.filter((f) => f.groupId === g.id)
                                            ?.sort((a, b) => i18n.t(a.title).localeCompare(i18n.t(b.title)))
                                            .map((t) => (
                                                <MenuItem
                                                    key={t.id}
                                                    component={
                                                        <Link
                                                            to={`/tables/${t.id}/data`}
                                                        />
                                                    }
                                                >
                                                    {i18n.t(
                                                        t.title,
                                                    )}
                                                </MenuItem>
                                            ))
                                        }
                                    </SubMenu>
                                ))}
                        </SubMenu>
                    }
                    {hasMenuAccess('REPORTS') &&
                        <SubMenu
                            label={i18n.t(`Relatórios`)}
                            icon={
                                <Icons.Relatorios size='1.5em' />
                            }
                        >
                            {reports.some(f => f.typeId !== 1) && 
                                <SubMenu
                                    label={'BI'}
                                    icon={
                                        <Icons.PBI size='1.5em' />
                                    }
                                >
                                    {reports
                                        .filter(
                                            (f) => f.typeId !== 1,
                                        )
                                        .sort((a, b) =>
                                            a.order > b.order
                                                ? 1
                                                : -1,
                                        )
                                        .map((r) => (
                                            <MenuItem
                                                key={r.id}
                                                component={
                                                    <Link
                                                        to={`/reports/${r.id}`}
                                                    />
                                                }
                                                title={
                                                    r.description
                                                }
                                            >
                                                {i18n.t(r.name)}
                                            </MenuItem>
                                        ))}
                                </SubMenu>}
                            <SubMenu
                                label={i18n.t(`Dashboards`)}
                                icon={
                                    <Icons.Superset size='1.5em' />
                                }
                            
                            >
                                {reports
                                    .filter(
                                        (f) => f.typeId === 1,
                                    )
                                    .sort((a, b) =>
                                        a.order > b.order
                                            ? 1
                                            : -1,
                                    )
                                    .map((r, index) => (
                                        <MenuItem
                                            style={{ marginBottom: index === reports.filter(f=>f.typeId===1).length - 1 ?  '8rem'  : '0' }}
                                            key={r.id}
                                            component={
                                                <Link
                                                    to={`/reports/${r.id}`}
                                                />
                                            }
                                            title={
                                                r.description
                                            }
                                        >
                                            {i18n.t(r.name)}
                                        </MenuItem>
                                    ))}
                            </SubMenu>
                        </SubMenu>
                    }
                </Menu>
            </Sidebar>
        </SidebarContainer>
    );
};

const MemoSidebarMenu = React.memo(SidebarMenu);
export { MemoSidebarMenu as SidebarMenu };
